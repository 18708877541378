<template>
  <div class="bg-gray-200 sticky top-0 z-50 shadow-md">
    <nav
      id="app"
      class="container flex items-center flex-wrap p-3 justify-between"
    >
      <a
        href=""
        v-scroll-to="'#app'"
      >
        <app-logo />
      </a>
      <div class="lg:hidden xl:hidden">
        <button
          @click="toggleMenu"
          class="flex items-center border rounded focus:outline-none"
        >
          <svg
            class="fill-current h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        :class="{'hidden': menu}"
        class="block w-full items-center justify-end xl:w-auto lg:w-auto lg:flex"
      >
        <a
          href="#"
          v-scroll-to="'#app'"
          class="block mt-4 lg:inline-block lg:mt-0 sm:text-right font-medium no-underline text-main-black hover:underline mr-4"
        >
          Home
        </a>
        <a
          href="#"
          v-scroll-to="'#about'"
          class="block mt-4 lg:inline-block lg:mt-0 sm:text-right font-medium no-underline text-main-black hover:underline mr-4"
        >
          Nosotros
        </a>
        <a
          href="#"
          v-scroll-to="'#products'"
          class="block mt-4 lg:inline-block sm:text-right lg:mt-0 font-medium no-underline text-main-black hover:underline mr-4"
        >
          Software
        </a>
        <a
          href="#"
          v-scroll-to="'#contact'"
          class="block mt-4 lg:inline-block sm:text-right lg:mt-0 font-medium no-underline text-main-black hover:underline mr-4"
        >
          Contáctanos
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import AppLogo from './AppLogo.vue';

export default {
  components: {
    AppLogo,
  },
  data() {
    return {
      menu: true,
    };
  },
  methods: {
    toggleMenu() {
      this.menu = !this.menu;
    },
  },
};
</script>
