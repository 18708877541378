import { createApp } from "vue";
import VueScrollTo from "vue-scrollto";
import "../css/application.css";
import Landing from "../components/Landing.vue";

const app = createApp(Landing);

app.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease-in-out",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

document.addEventListener("DOMContentLoaded", () => {
  app.mount("#app");
});
