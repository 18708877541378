<template>
  <div id="about">
    <div class="container flex">
      <div
        class="flex flex-col-reverse lg:flex-row py-10 px-5 m-auto w-full h-full text-center"
      >
        <div class="m-auto max-w-sm lg:max-w-xl">
          <img src="../assets/images/handshake.png" />
        </div>
        <div class="m-auto max-w-lg">
          <h1 class="font-semibold text-3xl lg:text-5xl">Nosotros</h1>
          <div class="my-10 opacity-50 text-xl text-center lg:text-left">
            <p class="my-4">
              Los perfiles de nuestro equipo son financieros, de análisis quant
              y de desarrollo de software.
            </p>
            <p class="my-4">
              Buscamos la unión de esos mundos, lo que se refleja en el
              background de nuestros colaboradores. Todo lo automatizable lo
              haremos automático.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
