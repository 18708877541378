<template>
  <div
    class="header-background"
    :style="{
      'background-image':
        'url(' + require('../assets/images/exchange2.png') + ')',
    }"
  >
    <div
      class="bg-purple-900 bg-opacity-75"
      style="
        background: linear-gradient(
          90deg,
          rgba(53, 39, 115, 1) 30%,
          rgba(53, 39, 115, 0.75) 100%
        );
      "
    >
      <div class="container flex" style="min-height: 600px; height: 80vh">
        <div class="text-white py-10 m-auto md:ml-0">
          <h2 class="text-3xl lg:text-5xl font-black">
            Arbitrage Trading Company
          </h2>
          <p class="text-lg lg:text-xl text-gray-400">
            Somos una firma de capitales chilenos que opera en diversos países
            de Latinoamérica.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
