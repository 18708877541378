<template>
  <div>
    <navigation-menu />
    <app-header />
    <!-- <app-preface /> -->
    <app-values />
    <app-about />
    <app-features />
    <!-- app-contact /> -->
    <app-footer />
  </div>
</template>

<script>
import AppAbout from './AppAbout.vue';
import AppFooter from './AppFooter.vue';
import AppHeader from './AppHeader.vue';
import NavigationMenu from './NavigationMenu.vue';
import AppFeatures from './AppFeatures.vue';
import AppValues from './AppValues.vue';

export default {
  components: {
    AppAbout,
    AppFooter,
    AppHeader,
    NavigationMenu,
    AppFeatures,
    AppValues,
  },
};
</script>
