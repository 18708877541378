<template>
  <div id="contact" class="bg-purple-900">
    <div class="flex flex-col">
      <a href="#" v-scroll-to="'#app'">
        <img src="../assets/images/up-arrow.svg" class="h-6 mx-auto" />
      </a>
    </div>
    <div class="py-5 text-base text-white text-left">
      <div class="flex">
        <div class="flex mx-auto px-5 flex-col lg:flex-row">
          <div class="flex justify-center items-center py-1">
            <img src="../assets/images/phone.svg" class="h-4 mr-2" />
            <a href="tel:+56996148826"> +56 9 96148826</a>
          </div>
          <div class="w-5" />
          <div class="flex justify-center items-center py-1">
            <img src="../assets/images/mail.svg" class="h-4 mr-2" />
            <a href="mailto:info@pgb.group">info@pgb.group</a>
          </div>
          <div class="w-5" />
        </div>
      </div>
      <div class="flex justify-center items-center py-1">
        <img src="../assets/images/location.svg" class="h-4 mr-2" />
        <a href="https://maps.app.goo.gl/38JdSy61mjX83WLY7" target="_blank">
          <p class="hidden lg:block">
            Carlos Antúnez #2025, Oficina 807. Providencia, Santiago, Chile
          </p>
          <p class="block lg:hidden">Carlos Antúnez #2025</p>
        </a>
      </div>
      <div class="flex justify-center">
        <a href="https://www.linkedin.com/company/pgb-group/" target="_blank">
          <img src="../assets/images/linkedin.svg" class="h-10 m-5" />
        </a>
      </div>
      <p class="text-center">© 2024 PGB SA</p>
    </div>
  </div>
</template>
