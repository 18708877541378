<template>
  <div id="products" class="bg-gray-200">
    <div class="container text-center py-10 pb-20">
      <div class="">
        <div>
          <h1 class="text-main-black font-semibold text-3xl lg:text-5xl">
            Nuestro Software
          </h1>
          <div class="m-10 opacity-50 text-xl">
            <p>
              Nuestra plataforma está hosteada en la nube, es multi-usuario y
              funciona 24/7.
            </p>
          </div>
        </div>
        <div class="md:flex sm:block justify-center px-auto">
          <div class="px-3 py-3 text-center">
            <img
              src="../assets/images/automatization.png"
              alt="automatization image"
              class="h-16 m-auto"
            />
            <h3 class="mb-4">Automatización</h3>
            <p class="text-black font-base text-sm opacity-50">
              Permite la toma de decisiones automatizadas
            </p>
          </div>
          <div class="px-3 py-3 text-center">
            <img
              src="../assets/images/integration.png"
              alt="integration image"
              class="h-16 m-auto"
            />
            <h3 class="mb-4">Integración</h3>
            <p class="text-black font-base text-sm opacity-50">
              Enfocado en una integración a la medida
            </p>
          </div>
          <div class="px-3 py-3 text-center">
            <img
              src="../assets/images/reporting.png"
              alt="reporting image"
              class="h-16 m-auto"
            />
            <h3 class="mb-4">Reportería</h3>
            <p class="text-black font-base text-sm opacity-50">
              Sistema de reportería integrado
            </p>
          </div>
        </div>
        <div
          class="xl:inline-flex lg:inline-flex md:inline-flex sm:block xs:block justify-center px-auto"
        >
          <div class="">
            <div class="p-3 m-auto text-center max-w-sm">
              <img
                src="../assets/images/accounting.png"
                alt="accounting image"
                class="h-16 m-auto"
              />
              <h3 class="mb-4">Contabilidad</h3>
              <p class="text-black font-base text-sm opacity-50">
                Mantiene completa trazabilidad de todas las órdenes realizadas
              </p>
            </div>
            <div class="p-3 m-auto text-center max-w-sm">
              <img
                src="../assets/images/strategy.png"
                alt="accounting image"
                class="h-16 m-auto"
              />
              <h3 class="mb-4">Estrategias</h3>
              <p class="text-black font-base text-sm opacity-50">
                Permite implementación de estrategias inteligentes de arbitraje
              </p>
            </div>
          </div>
          <div class="hidden lg:block justify-center px-auto">
            <img src="../assets/images/process.png" class="max-w-xs" />
          </div>
          <div class="">
            <div class="p-3 m-auto text-center max-w-sm">
              <img
                src="../assets/images/security.png"
                alt="security image"
                class="h-16 m-auto"
              />
              <h3 class="mb-4">Seguridad</h3>
              <p class="text-black font-base text-sm opacity-50">
                Un sistema de whitelist permite restringir los retiros de la
                aplicación
              </p>
            </div>
            <div class="p-3 m-auto text-center max-w-sm">
              <img
                src="../assets/images/ready.png"
                alt="ready image"
                class="h-16 m-auto"
              />
              <h3 class="mb-4">Siempre Listo</h3>
              <p class="text-black font-base text-sm opacity-50">
                Una vez definidas las estrategias, solo queda esperar al mercado
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
