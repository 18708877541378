<template>
  <div id="values" class="bg-gray-200">
    <div class="container py-20 w-full text-left">
      <h3 class="text-2xl lg:text-4xl font-bold text-center mb-12">
        Líderes en el desarrollo de software de arbitraje
      </h3>
      <div class="flex flex-col lg:flex-row">
        <div class="flex-1 mx-5 mb-12 lg:mb-0">
          <div class="flex">
            <img
              src="../assets/images/shield.png"
              class="object-contain w-12 lg:w-20"
            />
            <h5 class="font-semibold text-xl lg:text-2xl m-2 lg:ml-5">
              Utilizamos los más altos estándares de seguridad de la industria
            </h5>
          </div>
          <p class="text-md lg:text-lg lg:mt-5 text-gray-800">
            Seguimos las prácticas del estándar ISO 27001 y usamos los últimos
            protocolos de seguridad para nuestros datos.
          </p>
        </div>
        <div class="flex-1 mx-5 mb-12 lg:mb-0">
          <div class="flex">
            <img
              src="../assets/images/expert.png"
              class="object-contain w-12 lg:w-20"
            />
            <h5 class="font-semibold text-lg lg:text-2xl m-2 lg:ml-5">
              Contamos con más de 4 años de experiencia en arbitraje
            </h5>
          </div>
          <p class="text-md lg:text-lg lg:mt-5 text-gray-800">
            El año 2019 abrimos la primera oficina en Chile y hoy nos
            encontramos operacionales en 3 países de Latinoamérica.
          </p>
        </div>
        <div class="flex-1 mx-5">
          <div class="flex">
            <img
              src="../assets/images/bdo.svg"
              class="object-contain w-12 lg:w-20 h-12 lg:h-20 self-center p-1"
              style="border: 3px solid black; border-radius: 50%"
            />
            <h5 class="font-semibold text-lg lg:text-2xl m-2 lg:ml-5">
              Trabajamos con equipos externos para auditarnos
            </h5>
          </div>
          <p class="text-md lg:text-lg lg:mt-5 text-gray-800">
            Nuestros estados financieros se encuentran auditados por BDO, una de
            las empresas líderes de la industria.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
